<copyright>
File: reminder.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'gdpr-reminder',
  props: ['webview', 'newAccount', 'app', 'loading', 'reminderOnly'],
};

</script>

<template lang="pug">

loader(:loading="loading")
  h1(:data-name="$name('title')") {{ $t('Parallels User Account Data Privacy Notice') }}
  p(v-if="!reminderOnly")
    formatter(:text="$t('Please review our {policy_link}Privacy Policy{/policy_link} and the {terms_link}Terms of Use{/terms_link}. Click the button below to confirm your agreement with the terms. This is a one-time step.')")
      a(slot="policy_link", href="https://parallels.com/privacy", slot-scope="s", target="_blank") {{ s.text }}
      a(slot="terms_link", href="https://parallels.com/terms", slot-scope="s", target="_blank") {{ s.text }}
      span.link(slot="settings_link", slot-scope="s", @click="$emit('switch')") {{ s.text }}
  h2 {{ $t('How We Use Your Data') }}
  p
    formatter(:text="$t('We use the information we collect, along with the data you voluntarily provide, strictly for the purposes outlined in the {policy_link}Privacy Policy{/policy_link}. This may include:')")
      a(slot="policy_link", href="https://parallels.com/privacy", slot-scope="s", target="_blank") {{ s.text }}
  .margin-bottom-2x
    collapsible
      template(slot="title") {{ $t('Login credentials') }}
      template(slot="content")
        p {{ $t('We securely store the account credentials you provide during registration. If you log in using third-party services such as Apple, Google, or Facebook, we receive your email address from these services, provided you have given consent.') }}
        p.text-bold {{ $t('Data We Collect:') }}
        ul
          li
            formatter(:text="$t('{account_email_address} uniquely identifies your Parallels account.')")
              b(slot="account_email_address") {{ $t('Account Email Address:') }}
          li
            formatter(:text="$t('{account_password} Stored only if you register or log in to your Parallels account using your email and password.')")
              b(slot="account_password") {{ $t('Account Password:') }}
        p.text-bold {{ $t('How We Use Your Information:') }}
        ul
          li
            formatter(:text="$t('{identification} Each time you log in to your Parallels account, whether through the Parallels application or a web browser, we use your email address and password to verify your identity. Rest assured, we never store your password in plain text. Instead, we convert it into an unrecognizable value and then encrypt it, ensuring that no one can view or guess your original password.')")
              b(slot="identification") {{ $t('Identification:') }}
          li
            formatter(:text="$t('{communications} We use your account email address to send you emails only if you have agreed to receive notifications related to your registered Parallels products. These may include critical updates, subscription details, trial information, new product announcements, billing notifications, special offers, and beta versions of Parallels products.')")
              b(slot="communications") {{ $t('Communications:') }}
    collapsible
      template(slot="title") {{ $t('Information about your Parallels account') }}
      template(slot="content")
        p {{ $t('We collect and store information both from your interactions with our service and the data you voluntarily provide during account registration or profile updates.') }}
        p.text-bold {{ $t('Data We Collect:') }}
        ul
          li
            formatter(:text="$t('{personal_details} Your first and last name.')")
              b(slot="personal_details") {{ $t('Personal Details:') }}
          li
            formatter(:text="$t('{location_information} Initially detected based on your IP address, including your country, state, city, and time zone.')")
              b(slot="location_information") {{ $t('Location Information:') }}
          li
            formatter(:text="$t('{access_records} Information on the web browsers and mobile devices you use to access your Parallels account.')")
              b(slot="access_records") {{ $t('Access Records:') }}
          li
            formatter(:text="$t('{technical_data} Web browser name and version, operating system name and version, and a virtual browser ID generated by the Parallels web app.')")
              b(slot="technical_data") {{ $t('Technical Data:') }}
          li
            formatter(:text="$t('{login_history} The date and time of your last login for each browser or device, along with the session status (active or inactive).')")
              b(slot="login_history") {{ $t('Login History:') }}
          li
            formatter(:text="$t('{password_update} The date of your last account password change.')")
              b(slot="password_update") {{ $t('Password Update:') }}
        p.text-bold {{ $t('How We Use Your Information:') }}
        ul
          li
            formatter(:text="$t('{personalization} Your account profile details, such as your name and country, help us tailor your experience. This includes addressing you correctly in emails, showing accurate dates and times, and formatting currency according to your local standards.')")
              b(slot="personalization") {{ $t('Personalization:') }}
          li
            formatter(:text="($t('{transaction_processing} When you purchase a Parallels product subscription, we share your email, name, and country with our payment provider, {cb_link}Cleverbridge{/cb_link}. This enables us to customize your shopping experience by identifying your country, language, and currency, and pre-filling billing information with your details.'))")
              b(slot="transaction_processing") {{ $t('Transaction Processing:') }}
              a(slot="cb_link", href="https://grow.cleverbridge.com", slot-scope="s", target="_blank") {{ s.text }}
          li
            formatter(:text="$t('{security_measures} The data related to your location, web browsers, and devices is crucial for protecting your account from unauthorized access and improving overall security. We are committed to safeguarding your information and using it to enhance your experience with Parallels.')")
              b(slot="security_measures") {{ $t('Security Measures:') }}
    collapsible
      template(slot="title") {{ $t('Information about your Parallels product licenses') }}
      template(slot="content")
        p {{ $t('We collect and store information about your Parallels product licenses.') }}
        p.text-bold {{ $t('Data We Collect:') }}
        ul
          li
            formatter(:text="$t('{license_data} Details of your Parallels subscriptions and licenses, such as product type, purchase date, activation date, expiration date, renewal date, license key, number of licenses, and assigned subscription name.')")
              b(slot="license_data") {{ $t('License Data:') }}
          li
            formatter(:text="$t('{license_usage} When you activate a license on your computer (Mac or PC), we store the hardware UUID, a unique identifier assigned to your computer.')")
              b(slot="license_usage") {{ $t('License Usage:') }}
        p.text-bold {{ $t('How We Use Your Information:') }}
        ul
          li
            formatter(:text="$t('{product_activation} We use information about your product licenses to activate your Parallels products and prevent unauthorized use.')")
              b(slot="product_activation") {{ $t('Product Activation:') }}
          li
            formatter(:text="$t('{special_offers} Additionally, this information helps us offer you exclusive promotions and discounts tailored for our loyal customers.')")
              b(slot="special_offers") {{ $t('Special Offers:') }}
    collapsible
      template(slot="title") {{ $t('General details about your payment activities') }}
      template(slot="content")
        p
          formatter(:text="($t('When you purchase Parallels product licenses or subscriptions from the Parallels Online Store, we process the payment information provided by our payment provider, {cb_link}Cleverbridge{/cb_link}. We do not receive or store your credit card number, PayPal account ID, or bank account number. Instead, {cb_link}Cleverbridge{/cb_link} shares with us only the data that you voluntarily provide during the purchase, which cannot be used to identify your financial details.'))")
            a(slot="cb_link", href="https://grow.cleverbridge.com", slot-scope="s", target="_blank") {{ s.text }}
        p.text-bold {{ $t('Data We Collect:') }}
        ul
          li
            formatter(:text="$t('{your_name} First and last name.')")
              b(slot="your_name") {{ $t('Your Name:') }}
          li
            formatter(:text="$t('{language} The language you selected in the shopping cart.')")
              b(slot="language") {{ $t('Language:') }}
          li
            formatter(:text="$t('{email_address} If different from your account email.')")
              b(slot="email_address") {{ $t('Email Address:') }}
          li
            formatter(:text="$t('{payment_details} Includes currency, payment type (e.g., PayPal, bank transfer, or credit card type such as Visa or MasterCard). We never receive or process your credit card details, including the card number or CVV/CVC code.')")
              b(slot="payment_details") {{ $t('Payment Details:') }}
          li
            formatter(:text="$t('{purchase_confirmation} URL of your transaction confirmation page and invoice (PDF document URL).')")
              b(slot="purchase_confirmation") {{ $t('Purchase Confirmation:') }}
          li
            formatter(:text="$t('{product_information} Type of product purchased, total amount paid in your currency.')")
              b(slot="product_information") {{ $t('Product Information:') }}
          li
            formatter(:text="$t('{subscription_id} Unique identifier for your subscription.')")
              b(slot="subscription_id") {{ $t('Subscription ID:') }}
          li
            formatter(:text="$t('{cancellation_and_payment_option_change_urls} URLs for canceling your subscription and changing payment options.')")
              b(slot="cancellation_and_payment_option_change_urls") {{ $t('Cancellation and Payment Option Change URLs:') }}
        p.text-bold {{ $t('How We Use Your Information:') }}
        ul
          li
            formatter(:text="$t('{license_provisioning} We use this information to provide you with your product license key(s), manage your purchase history in your Parallels account, enable additional license purchases, and handle subscription management, renewal, or cancellations.')")
              b(slot="license_provisioning") {{ $t('License Provisioning:') }}
        p
          formatter(:text="($t('Rest assured, we do not receive or process any data that could identify your credit card, PayPal, or bank account information. All sensitive payment details are securely processed and stored by {cb_link}Cleverbridge{/cb_link} on their servers.'))")
            a(slot="cb_link", href="https://grow.cleverbridge.com", slot-scope="s", target="_blank") {{ s.text }}
    collapsible
      template(slot="title") {{ $t('Technical report data') }}
      template(slot="content")
        p {{ $t('When you send a technical report from a Parallels product, we process the technical data you voluntarily provide. You can choose to include or exclude any of the following data categories in your report.') }}
        p.text-bold {{ $t('Data We Collect:') }}
        ul
          li
            formatter(:text="$t('{product_information} Name, version, and license key (if applicable).')")
              b(slot="product_information") {{ $t('Product Information:') }}
          li
            formatter(:text="$t('{virtual_product_id} A unique identifier not associated with any individual.')")
              b(slot="virtual_product_id") {{ $t('Virtual Product ID:') }}
          li
            formatter(:text="$t('{hardware_and_software_details} Includes your computer’s hardware ID, configuration, operating system details (name, version, configuration), and a list of installed and running applications.')")
              b(slot="hardware_and_software_details") {{ $t('Hardware and Software Details:') }}
          li
            formatter(:text="$t('{virtual_machine_data} Details of the virtual machine`s hardware configuration, operating system (name, version, configuration), installed and running applications, and a dump of the virtual machine`s memory.')")
              b(slot="virtual_machine_data") {{ $t('Virtual Machine Data:') }}
          li
            formatter(:text="$t('{configuration_and_logs} Product configuration files (which may list your registered virtual machines) and operation logs.')")
              b(slot="configuration_and_logs") {{ $t('Configuration and Logs:') }}
          li
            formatter(:text="$t('{operating_system_logs} Logs from your computer’s operating system.')")
              b(slot="operating_system_logs") {{ $t('Operating System Logs:') }}
          li
            formatter(:text="$t('{screenshots} Images of your computer’s and virtual machine’s desktops.')")
              b(slot="screenshots") {{ $t('Screenshots:') }}
          li
            formatter(:text="$t('{attachments} Any additional files you include with the technical report.')")
              b(slot="attachments") {{ $t('Attachments:') }}
          li
            formatter(:text="$t('{contact_information} Email and name (if different from your account email address).')")
              b(slot="contact_information") {{ $t('Contact Information:') }}
          li
            formatter(:text="$t('{problem_description} Details about the issue you provide voluntarily.')")
              b(slot="problem_description") {{ $t('Problem Description:') }}
        p.text-bold {{ $t('How We Use Your Information:') }}
        ul
          li
            formatter(:text="$t('{problem_analysis} This information helps us analyze the problem, understand its cause, and improve our products to better serve you.')")
              b(slot="problem_analysis") {{ $t('Problem Analysis:') }}
    collapsible
      template(slot="title") {{ $t('Support data') }}
      template(slot="content")
        p {{ $t('When you contact Parallels Support via phone, chat, or email, we process your messages and any technical data you voluntarily provide.') }}
        p.text-bold {{ $t('Data We Collect:') }}
        ul
          li
            formatter(:text="$t('{contact_information} Email and name (if different from the account email address).')")
              b(slot="contact_information") {{ $t('Contact Information:') }}
          li
            formatter(:text="$t('{message_content} The text of your messages to Parallels Support, including any information you provided such as emails, names, and additional details.')")
              b(slot="message_content") {{ $t('Message Content:') }}
          li
            formatter(:text="$t('{attachments} Documents, screenshots, and other files attached to your emails or provided to our support engineers.')")
              b(slot="attachments") {{ $t('Attachments:') }}
          li
            formatter(:text="$t('{phone_number} The phone number used to contact support, if applicable.')")
              b(slot="phone_number") {{ $t('Phone Number:') }}
        p.text-bold {{ $t('How We Use Your Information:') }}
        ul
          li
            formatter(:text="$t('{provisioning_of_support} This information is used by our Support team to track your requests and resolve your issues as efficiently as possible.')")
              b(slot="provisioning_of_support") {{ $t('Provisioning of Support:') }}
    collapsible
      template(slot="title") {{ $t('Parallels Forums') }}
      template(slot="content")
        p {{ $t('When you use Parallels support forums, we process both the personal information you voluntarily provide and the data we collect automatically.') }}
        p.text-bold {{ $t('Data We Collect:') }}
        ul
          li
            formatter(:text="$t('{nickname} The username you choose on Parallels forums.')")
              b(slot="nickname") {{ $t('Nickname:') }}
          li
            formatter(:text="$t('{signature} Any signature you include on the forums (if applicable).')")
              b(slot="signature") {{ $t('Signature:') }}
          li
            formatter(:text="$t('{forum_activity} History of forums you’ve visited and threads you’ve watched.')")
              b(slot="forum_activity") {{ $t('Forum Activity:') }}
          li
            formatter(:text="$t('{post_history} Records of your posts, including text and any attached files.')")
              b(slot="post_history") {{ $t('Post History:') }}
          li
            formatter(:text="$t('{private_conversation} History of your private messages with other forum users, including text and attachments.')")
              b(slot="private_conversation") {{ $t('Private Conversations:') }}
          li
            formatter(:text="$t('{following_and_ignoring_lists} A list of users you follow and those you ignore.')")
              b(slot="following_and_ignoring_lists") {{ $t('Following and Ignoring Lists:') }}
        p.text-bold {{ $t('How We Use Your Information:') }}
        ul
          li
            formatter(:text="$t('{enhancing_user_experience} The personal information you provide helps us represent you effectively in the Parallels forums, while the additional data we collect aims to improve your overall user experience.')")
              b(slot="enhancing_user_experience") {{ $t('Enhancing User Experience:') }}
  h2 {{ $t('Data Retention Period') }}
  p {{ $t('We retain and process your personal data for as long as necessary to fulfill our contractual and legal obligations. This means we keep your data for the duration of our contractual relationship and while your account remains active. If you choose to delete your account or request its deletion, we will remove your personal data, except where its retention is required to preserve evidence or to prevent legal claims from becoming time-barred.') }}
  .last(v-if="!reminderOnly")
    h2 {{ $t('Your Consent is Required') }}
    p
      formatter(:text="$t('To continue using Parallels products and services, please review and accept our {policy_link}Privacy Policy{/policy_link} and {terms_link}Terms of Use{/terms_link}. By doing so, you confirm your consent to the data processing practices outlined in these documents.')")
        a(
          slot="policy_link",
          href="https://parallels.com/privacy",
          slot-scope="s",
          target="_blank",
          :data-name="$name('link-privacy')"
        ) {{ s.text }}
        a(
          slot="terms_link",
          href="https://parallels.com/terms",
          slot-scope="s",
          target="_blank",
          :data-name="$name('link-terms')"
        ) {{ s.text }}
  .buttons(v-if="!reminderOnly")
    btn(
      :class="{ 'block-xs': !webview }",
      @click="$emit('accept')",
      data-qa="button-accept-data-privacy-notice",
      :data-name="$name('button-accept')"
    ) {{ $t('Accept and Continue') }}
    btn(
      :class="{ 'block-xs': !webview }",
      color="transparent",
      @click="$emit('back')",
      data-qa="button-back-data-privacy-notice",
      :data-name="$name('button-back')"
    ) &larr; {{ $t('Back') }}

</template>

<style scoped lang="sass">

.page, .webview
  .buttons
    +clearfix
  button
    float: right
    margin-left: $grid-step * 2
    &:last-child
      float: left
      margin: 0

.webview
  .buttons
    padding-left: $grid-step * 2
    padding-right: $grid-step * 2

.page
  button
    +phone
      display: block
      margin: 0 0 $grid-step 0
      float: none

.toolbox.win
  .buttons
    padding-right: $grid-step * 3

.toolbox
  h1
    word-break: break-word

ul
  padding-left: $grid-step * 2

</style>
