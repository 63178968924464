<copyright>
File: intro.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'gdpr-intro',
  props: ['webview', 'newAccount', 'loading', 'tinyWidth'],
  data () {
    return {
      shield: require('./assets/shield.svg'),
    };
  },
};

</script>

<template lang="pug">

loader(:loading="loading")
  .margin-bottom-2x.clearfix
    h1(v-if="!webview", :data-name="$name('title')") {{ $t('Important Information') }}
    img.shield(:src="shield", alt="GDPR")
    h1(v-if="webview", data-qa="important-information-title", :data-name="$name('title')") {{ $t('Important Information') }}
    .shield-text
      formatter(:text="$t('We encourage you to review our {policy_link}Privacy Policy{/policy_link} and {terms_link}Terms of Use{/terms_link} to gain a comprehensive understanding of the personal information we collect and how we use it. This will help you see how we handle your data as we strive to provide you with the best Parallels products and services.')")
        a(
          slot="policy_link",
          href="https://parallels.com/privacy",
          slot-scope="s",
          target="_blank",
          :data-name="$name('link-privacy')"
        ) {{ s.text }}
        a(
          slot="terms_link",
          href="https://parallels.com/terms",
          slot-scope="s",
          target="_blank",
          :data-name="$name('link-terms')"
        ) {{ s.text }}
  .margin-bottom.text-bold.last(:class="{ 'text-center limited': !tinyWidth }") {{ $t('To continue using Parallels products and services, please review the information on the following pages.') }}
  .buttons.text-center
    btn.margin-right-4x(
      v-if="webview && newAccount",
      @click="$emit('cancel')",
      color="transparent",
      data-qa="button-cancel",
      :data-name="$name('button-cancel')"
    ) {{ $t('Later') }}
    btn.margin-right-4x.hidden-xs(
      v-else-if="newAccount",
      @click="$emit('back')",
      color="transparent",
      data-qa="button-back",
      :data-name="$name('button-back')"
    ) &larr; {{ $t('Back') }}
    btn.margin-right-2x.hidden-xs(
      v-if="!webview && !newAccount",
      @click="$emit('logout')",
      color="white",
      data-qa="button-logout",
      :data-name="$name('button-logout')"
    ) {{ $t('Sign Out') }}
    btn(
      :class="{ 'block-xs': !webview, 'margin-bottom-xs': !webview }",
      @click="$emit('switch')",
      data-qa="button-continue",
      :data-name="$name('button-continue')"
    ) {{ $t('Continue') }}
    template(v-if="!webview")
      btn.block-xs.visible-xs(
        v-if="!newAccount",
        @click="$emit('logout')",
        color="white",
        data-qa="button-logout",
        :data-name="$name('button-logout')"
      ) {{ $t('Sign Out') }}
      btn.block-xs.visible-xs(
        v-else,
        @click="$emit('back')",
        color="transparent",
        data-qa="button-back",
        :data-name="$name('button-back')"
      ) &larr; {{ $t('Back') }}

</template>

<style scoped lang="sass">

$image-height: $vertical-step * 19

.page
  .shield
    float: left
    height: $image-height
    margin: 0 $grid-step * 3
    +phone
      display: block
      float: none
      margin: 0 auto $grid-step * 2
    &-text
      display: table
      min-height: $image-height
      +phone
        display: block
        min-height: 0
      span
        display: table-cell
        vertical-align: middle

.webview
  .shield
    float: right
    height: $vertical-step * 17
    margin: 0 0 0 $grid-step * 3
  .limited
    margin: 0 auto $grid-step
    max-width: $grid-step * 50

.toolbox
  h1
    display: table
  .shield
    float: left
    height: $vertical-step * 10
    margin: 0 $grid-step * 2 $grid-step * 2 0
  .shield-text
    display: block
    min-height: 0
    +clearfix

</style>
