

import SubscriptionsRequest from '@/api/subscriptionsRequest';
import ProductsRequest from '@/api/productsRequest';

import {
  prodKeyToName,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_PTB_HDT,
  PRODUCT_KEY_NAME_PTB_DNST,
  PRODUCT_KEY_NAME_PTB_DVT,
  PRODUCT_KEY_NAME_PTB_CVT,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDPRO,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_MY_ACCOUNT,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_RAS_AZMP
} from '@core/constants/subscriptions';
import { GET_SUPPORT_PAGE } from '@/routes/constants';
import UnregisteredPdfcSupportModal from './unregisteredPdfcSupportModal.vue';
import Vue, { PropType } from 'vue';
import { ListBusinessDomainsRequestResponse } from '@/api/listBusinessDomainsRequest';

// no matter what version of the toolbox, it's all the same product. The same about PDB/PDB per user
const SUPPORT_FUNNEL = {
  [PRODUCT_KEY_NAME_PTBB]: PRODUCT_KEY_NAME_PTB,
  [PRODUCT_KEY_NAME_PTB_HDT]: PRODUCT_KEY_NAME_PTB,
  [PRODUCT_KEY_NAME_PTB_DNST]: PRODUCT_KEY_NAME_PTB,
  [PRODUCT_KEY_NAME_PTB_DVT]: PRODUCT_KEY_NAME_PTB,
  [PRODUCT_KEY_NAME_PTB_CVT]: PRODUCT_KEY_NAME_PTB,
  [PRODUCT_KEY_NAME_PDB]: PRODUCT_KEY_NAME_PDB,
  [PRODUCT_KEY_NAME_PDB_PER_USER]: PRODUCT_KEY_NAME_PDB,
  [PRODUCT_KEY_NAME_RAS_AZMP]: PRODUCT_KEY_NAME_RAS,
};

const ICONS_MAP = {
  [PRODUCT_KEY_NAME_PDFM]: 'pd',
  [PRODUCT_KEY_NAME_PDB]: 'pd',
  [PRODUCT_KEY_NAME_PDB_PER_USER]: 'pd',
  [PRODUCT_KEY_NAME_PDL]: 'pd',
  [PRODUCT_KEY_NAME_PDFC]: 'pdfc',
  [PRODUCT_KEY_NAME_RAS]: 'ras',
  [PRODUCT_KEY_NAME_PTB]: 'toolbox',
  [PRODUCT_KEY_NAME_PTBB]: 'toolbox',
  [PRODUCT_KEY_NAME_PTB_HDT]: 'hdt',
  [PRODUCT_KEY_NAME_PTB_DNST]: 'dnst',
  [PRODUCT_KEY_NAME_PTB_DVT]: 'dvt',
  [PRODUCT_KEY_NAME_PTB_CVT]: 'cvt',
  [PRODUCT_KEY_NAME_MY_ACCOUNT]: 'myacc',
  [PRODUCT_KEY_NAME_PSW]: 'psw',
  [PRODUCT_KEY_NAME_DAAS]: 'daas',
  [PRODUCT_KEY_NAME_PBI]: 'pbi',
  [PRODUCT_KEY_NAME_RAS_AZMP]: 'ras',
};

interface ProductItem {
  icon: string;
  key: string;
  name: string;
}

export default Vue.extend({
  name: 'support-step1-select-product',

  components: { UnregisteredPdfcSupportModal },

  props: {
    products: {
      type: Object,
    },
    supportedProducts: {
      type: Array as PropType<string[]>,
      required: true,
    },
    invitations: {
      type: Array as PropType<ListBusinessDomainsRequestResponse['domains']>,
      default: () => ([]),
    },
  },

  data () {
    return {
      registeredProducts: [],
      unregisteredProducts: [],
      loading: true,
      pdpro: false,
    };
  },

  created (): void {
    const request = new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_PERSONAL });
    this.$api.authorizedCall(request).then(() => {
      this.pdpro = request.getSubscriptions().some((subscription) => {
        return subscription.isActive && subscription.products.some((product) => {
          return ['pdfm11_pro', 'pdfm_pro_by_subscription'].indexOf(product.keyName) > -1;
        });
      });

      this.initProducts();
    }).finally(() => {
      this.loading = false;
    });
  },

  methods: {

    buildProductRows (products): ProductItem[][] {
      return products.reduce((rows, productKey, i) => {
        const n = Math.floor(i / 4);
        rows[n] = rows[n] || [];
        rows[n].push({
          key: productKey === PRODUCT_KEY_NAME_PDFM && this.pdpro ? PRODUCT_KEY_NAME_PDPRO : productKey,
          name: this.getProductName(productKey),
          icon: this.getIconName(productKey),
        });
        return rows;
      }, []);
    },

    initProducts (): void {
      let registeredProductsMap = [];
      if (Object.keys(this.products.personal).length > 0) {
        registeredProductsMap = registeredProductsMap.concat(this.products.personal[Object.keys(this.products.personal)[0]]);
      }
      if (Object.keys(this.products.business).length > 0) {
        for (const businessAccountId of Object.keys(this.products.business)) {
          registeredProductsMap = registeredProductsMap.concat(this.products.business[businessAccountId]);
        }
      }

      const filteredProductsList: string[] = Object.keys(registeredProductsMap
        .reduce((map, productKey) => {
          productKey = SUPPORT_FUNNEL[productKey] || productKey;
          map[productKey] = true;
          return map;
        }, []));

      this.supportedProducts.forEach((p) => {
        if (filteredProductsList.includes(p)) {
          this.registeredProducts.push(p);
        } else {
          this.unregisteredProducts.push(p);
        }
      });
    },
    async loadProducts (): Promise<void> {
      this.loading = true;
      const request = new ProductsRequest();

      try {
        await this.$api.authorizedCall(request);
      } finally {
        this.loading = false;
      }
    },
    async onCardClick (product): Promise<void> {
      this.$trackEvent({
        category: 'Support',
        name: 'Product card click',
        source: prodKeyToName(product.key),
      });

      if (product.key === PRODUCT_KEY_NAME_PDFC && this.unregisteredProducts.includes(product.key)) {
        await this.loadProducts();
        this.$modal.show(UnregisteredPdfcSupportModal, this.invitations);
        this.$emit('pdfcModalShown', this.invitations.map((invitation) => invitation.id));
      } else {
        this.$router.push({
          name: GET_SUPPORT_PAGE,
          params: {
            product: product.key,
            supportType: product.key === PRODUCT_KEY_NAME_MY_ACCOUNT ? 'license' : undefined,
          },
        });
      }
    },
    getIconName (productKey): string {
      return ICONS_MAP[productKey] || productKey;
    },
    getProductName (productKey): string {
      if (productKey === PRODUCT_KEY_NAME_PDFM) {
        return this.$t('Parallels Desktop for Mac Standard & Pro Editions');
      }
      return this.$t(prodKeyToName(productKey));
    },
  },

  computed: {
    registeredProductsRows (): ProductItem[][] {
      return this.buildProductRows(this.registeredProducts);
    },

    unregisteredProductsRows (): ProductItem[][] {
      return this.buildProductRows(this.unregisteredProducts);
    },
  },
});

