/**
 * File: downloadsContent.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue from 'vue';
import RasSubscriptionsRequest from '@/api/rasSubscriptionsRequest';
import ApiRequest from '@core/api/apiRequest';
import ComponentMixIn from '@/modules/componentMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import {
  prodKeyToName,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_RAS
} from '@core/constants/subscriptions';
import { FEATURE_PDFC_PRODUCT } from '@core/constants/features';
import { PRODUCT_CONTEXT_TO_DOWNLOAD_MAP } from '@/routes/constants';
import { getToolboxDownloadsRoute } from '@/modules/home/dashboard/utils';
import isEqual from 'lodash-es/isEqual';

const DEFAULT_LOCALE = 'en_US';
const URI_PREFIX = '/downloads/webapp/downloads/my_portal/';
const TAG_INSTALLER = 'installer';

// Generate string like "table-client-installation-file"
function getTableName (name: string): string {
  return name ? 'table-' + name.trim().replace(/\s+/g, '-').toLowerCase() : name;
}

// Transform string to a string suitable for search
function transformText (text: string): string {
  return text ? text.trim().toLowerCase() : '';
}

interface DownloadsTableRow {
  name: string;
  files: [];
  tableName: string;
}

interface VersionOption {
  builds: Record<string, string>;
  documents: Record<string, string>;
  text: string;
  value: string;
}

interface LanguageOption {
  text: string;
  value: string;
  url: string;
}

export default Vue.extend({
  name: 'downloads-content',

  mixins: [ComponentMixIn, SearchFilterMixin],

  props: {
    contextProduct: {
      type: String,
    },
  },

  data () {
    return {
      loading: false,
      searchFilters: {},
      product: undefined, // selected product (ddProducts)
      productOptions: [],
      version: null as string, // selected version
      versionOptions: [] as VersionOption[],
      language: null as string, // selected language
      languageOptions: [] as LanguageOption[],
      locale: DEFAULT_LOCALE,
      installationFiles: {
        columns: ['name', 'urls'],
        options: {
          sortable: ['name'],
          orderBy: { column: 'name' },
        },
        data: [],
      },
      documentation: {
        columns: ['name', 'urls'],
        options: {
          sortable: ['name'],
          orderBy: { column: 'name' },
        },
        data: [],
      },
      hasRasOfflineSubscription: false,
      documentationLoading: false,
    };
  },

  methods: {
    load (): void {
      this.productOptions = [];
      // TODO Make it configurable
      const request = new ApiRequest({ url: URI_PREFIX + 'index.json' });

      this.authorizedCall(request).then((data) => {
        // build productOptions
        Object.keys(data).forEach((productKeyName) => {
          if (productKeyName === PRODUCT_KEY_NAME_PDFC && !this.hasPdfc) {
            return;
          }
          this.productOptions.push({
            // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
            text: this.getProductName(productKeyName),
            value: productKeyName,
            url: data[productKeyName].index,
            locales: data[productKeyName].locales,
          });
        });
        const product = this.contextProduct;
        if (product && this.productOptions.find((o) => o.value === product)) {
          this.product = product;
        }
      });
    },

    async updateInstallationFilesData (localisedBuilds: string) {
      try {
        const data = await this.authorizedCall(new ApiRequest({ url: URI_PREFIX + localisedBuilds }));
        this.installationFiles.data = this.buildInstallationFilesData(data);
      } catch (e) {}
    },

    async fetchRasOfflineSubscription () {
      const rasSubscriptionsRequest = new RasSubscriptionsRequest();

      try {
        const subscriptions = await this.$api.authorizedCall(rasSubscriptionsRequest);
        if (subscriptions) {
          this.hasRasOfflineSubscription = rasSubscriptionsRequest.getSubscriptions().some((s) => s.offlineAllowed);
        }
      } catch (e) {}
    },

    async updateDocumentsData (locale: string) {
      const selectedOption = this.languageOptions.find((o) => o.value === locale);

      this.documentation.data = [];

      if (selectedOption) {
        try {
          const documentationData = await this.authorizedCall(new ApiRequest({ url: URI_PREFIX + selectedOption.url }), 'documentationLoading');
          if (this.isRas18DownloadPage && this.$appData.session.isBusinessAdmin) {
            await this.fetchRasOfflineSubscription();
          }
          this.documentation.data = this.buildDocumentsData(documentationData);
        } catch (e) {}
      }
    },

    buildInstallationFilesData (data): DownloadsTableRow[] {
      return data.map((item) => {
        const
          tableName = getTableName(item.category.name);
        const subcategories = item.contents.reduce((subcategories, category) => {
          if (!category.subcategory) {
            return subcategories;
          }
          // if we have two sections with same subcategory name - add section name to subcategory name
          if (item.contents.find((content) => {
            return content.subcategory === category.subcategory && content.name !== category.name;
          })) {
            const subcategory = `${category.subcategory}: ${category.name}`;
            subcategories[subcategory] = subcategory;
          } else {
            subcategories[category.subcategory] = category.subcategory;
          }
          return subcategories;
        }, {});
        const files = item.contents.filter((content) => {
          return !(content.tags && content.tags.includes(TAG_INSTALLER));
        }).reduce((files, content) => {
          const subcategory = subcategories[content.subcategory] || subcategories[`${content.subcategory}: ${content.name}`];
          if (content.file) {
            files.push({
              name: content.name,
              url: content.file,
              description: content.description,
              checksum: content.checksum,
              subcategory,
            });
          } else if (content.files) {
            if (subcategory) {
              // convert content.files object to array
              files = files.concat(Object.keys(content.files).map((name) => {
                return {
                  name: name,
                  url: content.files[name],
                  description: content.description,
                  checksum: content.checksum,
                  subcategory,
                };
              }));
            } else {
              files.push({
                name: content.name,
                urls: content.files,
                description: content.description,
                checksum: content.checksum,
                search: Object.keys(content.files).join(' '),
                subcategory,
              });
            }
          }
          return files;
        }, []);

        return {
          name: item.category.name,
          subcategories,
          subcategory: Object.keys(subcategories)[0],
          subcategoryLabel: item.category.subcategoryLabel,
          files,
          tableName,
          dropdownName: `${tableName}-dropdown`,
        };
      });
    },

    buildDocumentsData (data): DownloadsTableRow[] {
      const
        tableData = data.map((item) => {
          const files = item.contents.map((content) => {
            return {
              name: content.name,
              urls: content.files,
              search: Object.keys(content.files).join(' '),
            };
          });

          return {
            name: item.category.name,
            files,
            tableName: getTableName(item.category.name),
          };
        });

      if (this.hasRasOfflineSubscription && this.isRas18DownloadPage && tableData.length) {
        tableData[0].files.push({
          name: 'Parallels RAS Offline License Activation',
          urls: {
            PDF: 'https://download.parallels.com/ras/Parallels-RAS-Offline-License-Activation.pdf',
          },
        });
      }

      return tableData;
    },

    textFilter (entry, val): boolean {
      const name = transformText(entry.name);
      const files = transformText(entry.search);

      val = transformText(val);

      return [name, files].some((item) => item.includes(val));
    },

    transformInstallationData (data): any {
      return data.map((item) => {
        if (item.category.subcategoryLabel) {
          return {
            ...item,
            contents: item.contents.map((content) => {
              return Object.assign({}, content, {
                subcategory: content.subcategory || content.name,
              });
            }),
          };
        }
        return item;
      });
    },

    getProductName (keyName: string): string {
      if (keyName === PRODUCT_KEY_NAME_PDB) {
        return this.$t('Parallels Desktop for Mac Business Edition (per-device licensing)');
      } else if (keyName === PRODUCT_KEY_NAME_PDB_PER_USER) {
        return this.$t('Parallels Desktop for Mac Business Edition (per-user licensing)');
      } else {
        const name = prodKeyToName(keyName);
        return name ? this.$t(name) : null;
      }
    },
  },

  computed: {
    filteredInstallationFiles (): any[] {
      return this.installationFiles.data.map((category) => {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        const files = this.applySearchFilters(this.searchFilters, category.files);
        if (category.subcategory) {
          return files.filter((file) => file.subcategory === category.subcategory);
        }
        return files;
      });
    },

    showInstallationFilesSection (): boolean {
      return this.filteredInstallationFiles.some((items) => items.length);
    },

    filteredDocumentation (): any[] {
      return this.documentation.data.map((category) => {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        return this.applySearchFilters(this.searchFilters, category.files);
      });
    },

    showDocumentationSection (): boolean {
      return this.filteredDocumentation.some((items) => items.length);
    },

    classes (): { logo: string; search: string; version: string; product?: string } {
      return {
        logo: 'col-md-2 col-sm-12 with-product-selector',
        search: 'col-md-4 col-sm-4',
        product: 'col-md-4 col-sm-5',
        version: 'col-md-2 col-sm-3',
      };
    },

    hasPdfc (): boolean {
      const hasFeatureEnabled = this.$appData.session.isFeatureAccessible(FEATURE_PDFC_PRODUCT);
      const hasProduct = this.$appData.products.business.includes(PRODUCT_KEY_NAME_PDFC);

      return hasProduct || hasFeatureEnabled;
    },

    isRas18DownloadPage (): boolean {
      return this.product === PRODUCT_KEY_NAME_RAS && Number(this.version) >= 18;
    },
  },

  watch: {
    '$appData.session.isoLocale' (newVal: string) {
      if (newVal) {
        this.locale = newVal;
        this.product = undefined;
        this.load();
      }
    },
    product (newVal) {
      const { name: currentRouteName, params: currentRouteParams } = this.$route;
      const downloadRoute = this.product?.startsWith('ptb-') ? getToolboxDownloadsRoute(this.product) : PRODUCT_CONTEXT_TO_DOWNLOAD_MAP[this.product];
      const isSameRouteName = downloadRoute && downloadRoute.name === currentRouteName;
      const isSameRouteParams = downloadRoute && downloadRoute.params ? isEqual(downloadRoute.params, currentRouteParams) : true;
      const isSameRoute = isSameRouteName && isSameRouteParams;
      if (currentRouteName && downloadRoute && !isSameRoute) {
        this.$router.push(downloadRoute);
      }

      const versionOptions: VersionOption[] = [];
      const productOption = this.productOptions.find((o) => o.value === newVal);
      if (!productOption || !productOption.url) {
        return;
      }
      this.version = undefined;

      this.authorizedCall(new ApiRequest({ url: URI_PREFIX + productOption.url }))
        .then((data) => {
          Object.keys(data).sort().reverse().forEach((v) => {
            versionOptions.push({
              text: v,
              value: v,
              builds: data[v].builds || {},
              documents: data[v].documents || {},
            });
          });
          this.versionOptions = versionOptions;
          if (this.versionOptions.length) {
            // select first version by default if exists
            this.$nextTick(() => {
              this.version = this.versionOptions[0].value;
            });
          }
        })
        .catch(() => {
          this.versionOptions = [];
          this.version = undefined;
        });
    },

    version: async function (newVal) {
      this.installationFiles.data = [];
      // flush to re-init documentation table
      this.language = null;
      this.languageOptions = [];

      const productOption = this.productOptions.find((o) => o.value === this.product);
      const selectedOption = this.versionOptions.find((o) => o.value === newVal);
      const languageOptions: LanguageOption[] = [];

      const availableLocale = selectedOption?.builds[this.locale] ? this.locale : DEFAULT_LOCALE;
      const localisedBuilds = selectedOption?.builds[availableLocale];
      if (localisedBuilds) {
        await this.updateInstallationFilesData(localisedBuilds);
      }

      Object.keys(selectedOption?.documents || {}).forEach((language) => {
        languageOptions.push({
          text: productOption.locales[language],
          value: language,
          url: selectedOption.documents[language],
        });
      });

      this.$nextTick(() => {
        // $nextTick to initiate a change
        this.languageOptions = languageOptions;
        this.language = languageOptions.length ? languageOptions.find((option) => option.value === this.locale)?.value || DEFAULT_LOCALE : null;
      });
    },

    language: async function (newVal: string) {
      await this.updateDocumentsData(newVal);
    },
  },

  created () {
    this.locale = this.$appData.session.isoLocale || DEFAULT_LOCALE;
    this.load();
  },
});
